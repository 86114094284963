




























































import { Component, Vue } from 'vue-property-decorator';
import ContractSavingsAlmostReadyViewModel
  from '@/vue-app/view-models/components/contract-savings/investments/contract-savings-almost-ready-view-model';

@Component({ name: 'AlmostReady' })
export default class AlmostReady extends Vue {
  almost_ready_view_model = Vue.observable(
    new ContractSavingsAlmostReadyViewModel(this),
  );

  created() {
    this.almost_ready_view_model.initialize();
  }
}

