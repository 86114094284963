import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsAlmostReadyViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.contract-savings.investments.almost_ready';

  public constructor(view: any) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    this.view.$emit('nextStep');
  }

  initialize = () => {
    this.view.$emit('loadingInfo', false);
  }
}
